import { template as template_c8dd9452408c410cad5ca45b6e0ce989 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c8dd9452408c410cad5ca45b6e0ce989(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
