import { template as template_861f1c5c17704757bf8ca058b7e41478 } from "@ember/template-compiler";
const FKText = template_861f1c5c17704757bf8ca058b7e41478(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
