import { template as template_bc869ec227cc4db3b07d117456df5a4f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_bc869ec227cc4db3b07d117456df5a4f(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
