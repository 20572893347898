import { template as template_7ee13387fe9644a999c9ddc68a44f480 } from "@ember/template-compiler";
const FKLabel = template_7ee13387fe9644a999c9ddc68a44f480(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
